/* Lighty Framework 2.0
 * Copyright 2018- Giant Advertising Ltd.
 ****************************************

 Module: Typography

 * Made With  Matej Latin's gutenberg web typo starter kit
 * Documentation: http://matejlatin.github.io/Gutenberg/
 */

@use "sass:math";

/******** Base settings ********/
// Base calcculations
$base: 16 * (math.div($base-font-size, 100));
$leading: round($base * $line-height);
$leading-rem: math.div($leading, $base);

@if $typography {

    // hard reset
    *, html, body {
      font-size: 100%;
    }

    // body & paragraphs
    body {
      font-family: $paragraph-font-family;
      color: $base-type-color;
    }

    // headings
    h1, h2, h3, h4, h5, h6 {
      font-family: $heading-font-family;
      &.nm {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    // paragraphs
    p {
      font-family: $paragraph-font-family;
      color: $base-type-color;
      &.nm {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    // list styles
    ul {
      margin: 0;
      padding: 0;
      padding-left: 20px; /* indent to the paragraph's line */
      li {
        /* add same style as paragraph */
        font-family: $paragraph-font-family;
        color: $base-type-color;
      }
    }
    header ul li, footer ul li {
        margin-bottom: 0;
    }

    // links
    a {
      color: $base-type-color;
    }
    a:link {text-decoration: none;}
    a:visited {text-decoration: none;}
    a:hover {text-decoration: none;}
    a:active {text-decoration: none;}


    // complementary
    br {
        margin-bottom: 0; /* Clear margin for line breaks */
    }

    b,
    strong {
        font-weight: bold; /* Address style set to `bolder` in Firefox 4+, Safari, and Chrome. */
    }

    sub,
    sup {
      /* Prevent `sub` and `sup` affecting `line-height` in all browsers. */
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }
    sup {top: -0.5em;}
    sub {bottom: -0.25em;}


    // text align classes
    .tal {text-align: left;}
    .tac {text-align: center;}
    .tar {text-align: right;}

    // text transform classes
    .und {text-decoration: underline}
    .upp {text-transform: uppercase}
    .low {text-transform: lowercase}


    @if $typoraphy-type == basic {
        p {
            @include font-size(1);
            @include line-height(1.2);
            @include margin-top(0);
            @include margin-bottom(.3);
        }

        ul li {
            @include font-size(1);
            @include line-height(1.3);
            @include margin-top(0);
            @include margin-bottom(.3);
        }


        /******** Headings settings & Responsiveness ********/

        // Heading sizes
        $headings: (
        /* h1: size (in rem), line height (* leading), margin top (* leading), margin bottom (* leading) */
        h1: (3.9, 2, 1.5, .3),
        h2: (3.0, 1.8, .8, .3),
        h3: (2.4, 1.6, 1.2, .3),
        h4: (1.8, 1.4, 1.1, .3),
        h5: (1.4, 1.2, 1, .3),
        h6: (1, 1, .8, .3)
        ) !default;

        // Set headings sizes, line-heights and margins
        @each $heading, $properties in $headings {
            #{$heading}, .#{$heading} {
                @include font-size(nth($properties, 1));
                @include line-height(nth($properties, 2));
                @include margin-top(nth($properties, 3));
                @include margin-bottom(nth($properties, 4));
            }
        }

        // Responsiveness
        @media screen and (max-width: $small) {
            @each $heading, $properties in $headings {
                #{$heading}, .#{$heading} {
                @include font-size(nth($properties, 1)*0.5);
                @include line-height(nth($properties, 2)*0.6);
                @include margin-top(nth($properties, 3)*0.6);
                @include margin-bottom(nth($properties, 4)*0.6);
                }
            }
            h4 {
                @include font-size(1.15);
            }
            h5 {
                @include font-size(1);
            }
            h6 {
                @include font-size(0.85);
            }
        }
        @media screen and (min-width: $small) {
            @each $heading, $properties in $headings {
                #{$heading}, .#{$heading} {
                @include font-size(nth($properties, 1)*0.7);
                @include line-height(nth($properties, 2)*0.7);
                @include margin-top(nth($properties, 3)*0.7);
                @include margin-bottom(nth($properties, 4)*0.7);
                }
            }
            h5 {
                @include font-size(1.15);
            }
            h6 {
                @include font-size(1);
            }
        }

        @media screen and (min-width: $medium) {
            @each $heading, $properties in $headings {
                #{$heading}, .#{$heading} {
                @include font-size(nth($properties, 1)*0.8);
                @include line-height(nth($properties, 2)*0.8);
                @include margin-top(nth($properties, 3)*0.8);
                @include margin-bottom(nth($properties, 4)*0.8);
                }
            }
            h6 {
                @include font-size(1);
            }
        }

        @media screen and (min-width: $x-large) {
            @each $heading, $properties in $headings {
                #{$heading}, .#{$heading} {
                @include font-size(nth($properties, 1));
                @include line-height(nth($properties, 2));
                @include margin-top(nth($properties, 3));
                @include margin-bottom(nth($properties, 4));
                }
            }
        }
    }


}
