/* Lighty Framework 2.0
 * Copyright 2018- Giant Advertising Ltd.
 ****************************************

 Module: Responsive Typography

 * Made With  Mikes's responsive typography
 * Documentation: https://madebymike.com.au/writing/precise-control-responsive-typography/
 */

@use "sass:math";

/******** Base settings ********/
// Base calculations

// convert REM breakpoint back to PX for css Calc
@function parseInt($n) {
  @return math.div($n, ($n * 0 + 1));
}

@function rem-to-px($size) {
	$value: parseInt($size) * 16px; //16px is the base size specified
	@return round($value);
}

// responsive fluid typography calculation
@mixin fluid-type($min-font-size:16px, $max-font-size:24px, $lower-range:rem-to-px($small), $upper-range:rem-to-px($large)) {

    font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
    @media screen and (max-width: $lower-range) {
      font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range){
      font-size: $max-font-size;
    }
}

$headings: (
    h1: (28px, 63px, 31px, 15px),
    h2: (24px, 49px, 25px, 12px),
    h3: (22px, 39px, 20px, 10px),
    h4: (20px, 31px, 16px, 8px),
    h5: (18px, 24px, 12px, 8px),
    h6: (16px, 19px, 10px, 6px)
) !default;


@if $typoraphy-type == fluid {

  @each $heading, $properties in $headings {
    #{$heading}, .#{$heading} {
        @include fluid-type(nth($properties, 1), nth($properties, 2));
        margin-top: nth($properties, 3);
        margin-bottom: nth($properties, 4);
    }
  }

  p {
    @include fluid-type(16px, 19px);
    line-height: 1.4;
    font-weight: normal;
  }

}
